/* ---------- */
/* PERMANENT */
/* --------- */


$(function () {
    /**
     * Click alert :
     * -> la supprime.
     */
    const alerts = $('div.alert');
    $(alerts).on('click', function () {
        const currentAlert = $(this);
        $(this).css({
            'opacity': "0",
        });
        setTimeout(function () {
            $(currentAlert).remove()
        }, 475);
    });
});
