/* ---------- */
/* HISTORIQUE */
/* ---------- */

require('jquery-tablesort');

/**
 * blabla
 * @param {querySelector} page
 * @param {querySelector} target
 */
export function historique(page, target) {
    const fondModHistorique = $(page + ' > div.card-body > section#'+ target);
    if ($(fondModHistorique).length) {
        const modHistorique = $(page + ' > div.card-body > section#'+ target +' > article.modal-content');
        const btnHistorique = $(page + ' button#btnHistorique');
        /**
         * Click btn "Historique téléchargements" :
         * -> ouvre le modal de l'historique.
         */
        $(btnHistorique).on('click', function (event) {
            event.preventDefault();
            $(fondModHistorique).removeClass('hidden');
        });
        /**
         * Click sur bouton close :
         * -> ferme le modal,
         * -> supprime alerts.
         */
        const btnCloseHistorique = $(page + ' > div.card-body > section#'+ target +' > article.modal-content > div.modal-header > i');
        $(btnCloseHistorique).on('click', function () {
            $(fondModHistorique).addClass('hidden');
        });
        // /**
        //  * Click en dehors du contenu du modal :
        //  * -> le ferme.
        //  */
        // $(fondModHistorique).on('click', function (event) {
        //     if (!$(event.page).closest($(modHistorique)).length &&
        //         !$(event.page).is($(modHistorique))) {
        //         $(fondModHistorique).addClass('hidden');
        //     } else {}
        // });

        /**
         * Barre de recherche.
         */
        const icon = $('section#'+ target +' > article.modal-content > div.modal-body > div.form-group > i');
        const searchbar = $('section#'+ target +' > article.modal-content > div.modal-body > div.form-group > input#searchbar');
        $(icon).on('click', function () {
            $(searchbar).trigger('focus');
        });
        $(searchbar).on('keyup', function () {
            const tr = $('section#'+ target +' > article.modal-content > div.modal-body > table > tbody > tr');
            let value = $(this).val().toLowerCase();
            $(tr).filter(function () {
                $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            });
        });

        /**
         * Tri asc/desc sur th.
         */
        $('section#'+ target +' > article.modal-content > div.modal-body > table').tablesort();

        /**
         * Sauvegarde en sessionStorage le [] de pré-séléction des destinataire d'un email,
         * et affiche la page d'envoi d'email.
         */
        const iconEnvoiMails = $('section#'+ target +' > article.modal-content > div.modal-body > div.form-group > i:last-of-type');
        $(iconEnvoiMails).on('click', function () {
            const inputDestinataires = $('section#'+ target +' > article.modal-content > div.modal-body > div.form-group > input[type=hidden]');
            let destinataires = [];
            $.each($(inputDestinataires), function() {
                destinataires.push($(this).val());
            });
            sessionStorage.setItem('preselecteds', JSON.stringify(destinataires));
            window.location.replace('admin/gestion/email/envoi');
        });
    } else {}
}
