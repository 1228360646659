$(function () {
    /**
     * GLOBALS VAR : SELECTORS
     */
    const navMobile = $('body.sidebar-mini nav#nav-mobile');
    const navPC = $('body.sidebar-mini > div.wrapper > aside.main-sidebar');
    const footer = $('body.sidebar-mini > footer.main-footer');

    /**
     * GESTION NAVS
     */
    $(window).on('resize', function () {
        const windowWidthResized = $(window).width();
        if ($(navPC).hasClass('nav-mobile') && windowWidthResized < 576) {} else {
            if (windowWidthResized < 576) {
                $(navMobile).removeClass('hidden');
                $(navPC).addClass('hidden');
            } else {
                $(navMobile).addClass('hidden');
                $(navPC).removeClass('hidden');
                $(navPC).removeClass('nav-mobile');
                $(body).removeClass('hidden');
                $(footer).removeClass('hidden');
            }
        }
    });
    const windowWidthBase = $(window).width();
    if (windowWidthBase < 576) {
        if ($(navPC).hasClass('nav-mobile')) {} else {
            $(navMobile).removeClass('hidden');
            $(navPC).addClass('hidden');
            $(navPC).removeClass('nav-mobile');
            $(body).removeClass('hidden');
            $(footer).removeClass('hidden');
        }
    } else {
        $(navMobile).addClass('hidden');
        $(navPC).removeClass('hidden');
    }


    /* ---------------- */
    /*      MOBILE      */
    /* ---------------- */

    const buttonNavMobile = $('nav#nav-mobile > button');
    const body = $('body.sidebar-mini > div.wrapper > div.content-wrapper');
    $(buttonNavMobile).on('click', function () {
        $(body).addClass('hidden');
        $(footer).addClass('hidden');
        $(navPC).addClass('nav-mobile');
        $(navPC).removeClass('hidden');
    });


    /* ---------------- */
    /*        PC        */
    /* ---------------- */

    /**
     * LIENS MENU
     */
    const lisParent = $('ul.nav.nav-sidebar > li.has-treeview.nav-item > a.nav-link');
    $(lisParent).on('click', function () {
        // SOUS-LIENS
        const lienChild = $(this).siblings('ul.nav.nav-treeview');
        const liens = $(lienChild).find('li.has-treeview.nav-item.li2');
        $(liens).each(function () {
            if ($(this).children('a.nav-link').children('i').hasClass('fa-dot-circle')) {
                $(this).children('a.nav-link').children('i').addClass('fa-circle');
                $(this).children('a.nav-link').children('i').siblings('p').children('i.right').css({
                    'transform': "rotate(0deg)",
                });
                $(this).children('a.nav-link').children('i').removeClass('fa-dot-circle');
                // SI SOUS-SOUS-LIENS
                if ($(this).children('ul.nav.nav-treeview').children('li.nav-item.li3').children('ul.nav.nav-treeview')) {
                    const subLiens = $(this).children('ul.nav.nav-treeview').children('li.nav-item.li3').children('ul.nav.nav-treeview').parent('li.nav-item.li3');
                    $(subLiens).each(function () {
                        if ($(this).children('a.nav-link').children('p').children('i').css('transform') != 'undefined') {
                            $(this).children('a.nav-link').children('p').children('i').css({
                                'transform': "rotate(0deg)",
                            });
                        } else {}
                    });
                }
            } else {}
        });
    });


    /**
     * SOUS-LIENS MENU
     */
    const liens = $('li.nav-item.li2 > a.nav-link');
    $(liens).on('click', function () {
        // FERMÉ
        // -> ouvre
        if ($(this).siblings('ul.nav.nav-treeview').css('display') != 'block') {
            $(this).children('i').addClass('fa-dot-circle');
            $(this).children('i').siblings('p').children('i.right').css({
                'transform': "rotate(-90deg)",
            });
            $(this).children('i').removeClass('fa-circle');
            // SI SOUS-SOUS-LIENS
            if ($(this).siblings('ul.nav.nav-treeview').children('li.nav-item.li3').children('ul.nav.nav-treeview')) {
                const subLiens = $(this).siblings('ul.nav.nav-treeview').children('li.nav-item.li3').children('ul.nav.nav-treeview').parent('li.nav-item.li3');
                $(subLiens).each(function () {
                    if ($(this).children('a.nav-link').children('p').children('i').css('transform') != 'undefined') {
                        $(this).children('a.nav-link').children('p').children('i').css({
                            'transform': "rotate(0deg)",
                        });
                    } else {}
                });
            }
        // OUVERT
        // -> ferme
        } else if ($(this).siblings('ul.nav.nav-treeview').css('display') != 'none') {
            $(this).children('i').addClass('fa-circle');
            $(this).children('i').siblings('p').children('i.right').css({
                'transform': "rotate(0deg)",
            });
            $(this).children('i').removeClass('fa-dot-circle');
            // SI SOUS-SOUS-LIENS
            if ($(this).siblings('ul.nav.nav-treeview').children('li.nav-item.li3').children('ul.nav.nav-treeview')) {
                const subLiens = $(this).siblings('ul.nav.nav-treeview').children('li.nav-item.li3').children('ul.nav.nav-treeview').parent('li.nav-item.li3');
                $(subLiens).each(function () {
                    if ($(this).children('a.nav-link').children('p').children('i').css('transform') != 'undefined') {
                        $(this).children('a.nav-link').children('p').children('i').css({
                            'transform': "rotate(0deg)",
                        });
                    } else {}
                });
            }
        }
    });

    // Au survol de la souris.
    $(liens).on('mouseover', function () {
        $(this).css({
            'backgroundColor': "rgba(255,255,255,.1)",
        });
    });
    // Quand la souris sort.
    $(liens).on('mouseleave', function () {
        $(this).css({
            'backgroundColor': "transparent",
        });
    });


    /**
     * SOUS-SOUS-LIENS MENU
     */
    const subLiens = $('li.nav-item.li3 > ul.nav.nav-treeview').siblings('a.nav-link');
    $(subLiens).on('click', function () {
        // FERMÉ
        // -> ouvre
        if ($(this).siblings('ul.nav.nav-treeview').css('display') != 'block') {
            $(this).children('p').children('i.right').css({
                'transform': "rotate(-90deg)",
            });

            // OUVERT
            // -> ferme
        } else if ($(this).siblings('ul.nav.nav-treeview').css('display') != 'none') {
            $(this).children('p').children('i.right').css({
                'transform': "rotate(0deg)",
            });
        }
    });
});
