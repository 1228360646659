/* ----------- */
/* DRAG & DROP */
/* ----------- */

/**
 * Active le drag & drop et éxécute Ajax avec le nouvel ordre.
 * @param {route} route
 * @param {boolean} hasSiblings
 * @param {QuerySelector} dropzone
 */
export function dragDrop(route, hasSiblings, dropzone) {
    // Si plus de une 'dropzone' dans la page :
    if (hasSiblings == true) {
        $(dropzone).droppable({
            drop: function () {
                const form = {};
                let type;
                let targets;
                const currentDropzone = $(this);
                const x = setTimeout(function () {
                    targets = $(currentDropzone).children('ul.todo-list').children('li');
                    // Construction tableau avec en clé l'order et en valeur l'id de l'élément à déplacer.
                    for (let i = 0; i < $(targets).length; i++) {
                        form[(i + 1)] = $(targets)[i].getAttribute('name');
                        type = $(targets)[i].getAttribute('type');
                    }
                    const json = {
                        'elem': form,
                        'type': type,
                    };
                    $.ajax({
                        url: route,
                        type: 'PUT',
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                        },
                        dataType: 'json',
                        data: json,
                        success: function (data) {
                            console.log(data);
                        },
                        error: function (data) {
                            // console.log('erreur');
                            // console.log(data);
                        },
                        complete: function () {
                            // console.log('terminé');
                        },
                    });
                }, 100);
            },
        });
    // Si une seule 'dropzone' dans la page :
    } else if (hasSiblings == false) {
        $(dropzone).droppable({
            drop: function () {
                const form = {};
                let type;
                let li;
                const x = setTimeout(function () {
                    li = $('ul.todo-list').children('li');
                    // Construction tableau avec en clé l'order et en valeur l'id de l'élément à déplacer.
                    for (let i = 0; i < $(li).length; i++) {
                        form[(i + 1)] = $(li)[i].getAttribute('name');
                        type = $(li)[i].getAttribute('type');
                    }
                    const json = {
                        'elem': form,
                        'type': type,
                    };
                    $.ajax({
                        url: route,
                        type: 'PUT',
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                        },
                        dataType: 'json',
                        data: json,
                        success: function (data) {
                            // console.log(data);
                        },
                        error: function (data) {
                            // console.log('erreur');
                            // console.log(data);
                        },
                        complete: function () {
                            // console.log('terminé');
                        },
                    });
                }, 100);
            },
        });
    }

    // jQuery UI sortable for the todo list.
    $(dropzone + '> ul.todo-list').sortable({
        placeholder: 'sort-highlight',
        handle: '.handle',
        forcePlaceholderSize: true,
        zIndex: 999999,
    });
}
