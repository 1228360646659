/* --------- */
/* CHECKÉTAT */
/* --------- */

/**
 * Vérifie l'état (publié ou non) et update en fonction de checkbox checkée ou non.
 * @param {string} route
 */
export function checkEtat(route) {
    const checkboxs = $("ul li input.etat[type=checkbox]");
    $(checkboxs).on("change", function () {
        let json;
        if (this.checked) {
            json = {
                id: this.getAttribute("name"),
                etat: "1",
            };
        } else {
            json = {
                id: this.getAttribute("name"),
                etat: "0",
            };
        }
        $.ajax({
            url: route,
            type: "PUT",
            async: true,
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            dataType: "json",
            data: json,
            success: function (data) {
                // console.log(data);
                window.location.reload();
                return false;
            },
            error: function (data) {
                // console.log('erreur');
                // console.log(data);
            },
            complete: function () {
                // console.log('terminé');
            },
        });
    });
}
