/* ----------- */
/* BACK-OFFICE */
/* ----------- */

require('../bootstrap');

require('jqueryui');
require('jquery-knob');
require('jquery-timepicker/jquery.timepicker');
require('bootstrap-fileinput/js/fileinput.js');
require('bootstrap-fileinput/js/locales/fr.js');
require('overlayscrollbars');
require('admin-lte');
require('admin-lte/plugins/summernote/summernote-bs4.min.js');
require('jquery.scrollto');
require('alpinejs');
require('jquery-confirm');

require ('./permanent');

/* COMPONENTS */
/* ---------- */
require('./components/sidebar');
require('./components/checkEtat');
require('./components/dragDrop');
require('./components/historique');
require('./components/jquery.form');
